export default class Cookizi {
  constructor(args = false) {
    // Check cookie bar
    this.bar = document.querySelector('.cookizi-bar')
    if(!this.bar) {
      console.warn('Cookie bar element isn\'t valid. Sorry bro.')
      return false
    }

    // Check cookie sidebar
    this.sidebar = document.querySelector('.cookizi-sidebar')
    if(!this.sidebar) {
      console.warn('Cookie sidebar element isn\'t valid. Sorry bro.')
      return false
    } else {
      this.sidebar.style.display = 'block'
    }

    // Parameters
    this.cookieTimeout = 33696000000 // 13 months
    this.cookies = {
      'analytics': ['__utma', '__utmb', '__utmc', '__utmt', '__utmv', '__utmz', '_ga', '_gat', '_gid']
    }
    this.bots = /bot|googlebot|crawler|spider|robot|crawling/i
    this.validTypes = ['statistics']
    this.types = []
    this.launched = []
    this.deleted = []
    this.scroll = false

    // Get types args
    if(args && Array.isArray(args.types))
      this.types = args.types

    // Get scroll arg
    if(args && Number.isInteger(args.scroll))
      this.scroll = args.scroll
    
    // Return false if : bot / doNotTrack / ever refused
    if(this.isBot() || !this.isToTrack())
      return false

    // If ever accepted, launch function. Else, init cookie bar
    if(this.answered()) {
      this.launch()
    } else {
      this.initBar()
      this.launch()
    }

    // Init sidebar
    this.initSidebar()
  }

  initBar() {
    // Set cookizi cookie
    this.setCookie('cookizi', false)

    // Set types cookies to true on init
    for (let i = 0; i < this.types.length; i++) {
      this.setCookie(this.types[i], false)
    }

    // Get dom
    this.acceptButton = this.bar.querySelector('.cookizi-bar__accept')
    this.refuseButton = this.bar.querySelector('.cookizi-bar__refuse')
    this.preferencesButton = this.bar.querySelector('.cookizi-bar__preferences')
    this.politicsButton = this.bar.querySelector('.cookizi-bar__politics')

    // Listen cookie bar
    this.refuseButton.addEventListener('click', this.refuseCookies.bind(this))
    this.acceptButton.addEventListener('click', this.acceptCookies.bind(this))
    this.preferencesButton.addEventListener('click', this.showSidebar.bind(this))

    // Listen scroll
    if(this.scroll) {
      this.initScroll()
    }

    // Set cookizi to false on politics click

    // Show cookie bar
    this.bar.style.display = 'block'
  }

  initScroll() {
    window.addEventListener('scroll', this.listenScroll.bind(this))
  }

  listenScroll() {
    // // Listen scroll only if we didnt ever reach percent
    // if(!this.scroll)
    //   return false

    // const h = document.documentElement
    // const b = document.body
    // const st = 'scrollTop'
    // const sh = 'scrollHeight'
    // const percent = (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100
    
    // // Accept cookies when user scrolled enough
    // if(percent >= this.scroll) {
    //   this.scroll = false
    //   this.acceptCookies()
    // }
  }

  initSidebar() {
    // Init switch for each types
    for (let i = 0; i < this.types.length; i++) {
      this.initSwitch(this.sidebar.querySelector(`.cookizi-sidebar__switch[data-type="${this.types[i]}"]`))
    }

    // Hide sidebar on mask click
    this.sidebar.querySelector('.cookizi-sidebar__mask').addEventListener('click', e => {
      this.hideSidebar()
    })

    // Listen save click
    this.sidebar.querySelector('.cookizi-sidebar__save').addEventListener('click', e => {
      e.preventDefault()
      this.setCookie('cookizi', true)
      this.registerSidebarCookies()
      this.hideSidebar()
      this.launch()
      // Hide cookie bar
      this.bar.style.display = 'none'
    })
  }

  initSwitch(el) {
    // Listen switch
    el.addEventListener('click', event=> {
      event.target.dataset.value = event.target.dataset.value == 0 ? 1 : 0
    })
  }

  showSidebar() {
    event.preventDefault()
    this.updateSidebar()
    this.sidebar.classList.add('m-cookie-sidebar--shown')
  }

  hideSidebar() {
    this.sidebar.classList.remove('m-cookie-sidebar--shown')
  }

  updateSidebar() {
    // Set types cookies to cookie value
    for (let i = 0; i < this.types.length; i++) {
    this.setCookie(this.types[i], this.getCookie(this.types[i]))
    this.sidebar.querySelector(`.cookizi-sidebar__switch[data-type="${this.types[i]}"]`).setAttribute('data-value', this.getCookie(this.types[i]) ? 1 : 0)
    }
  }

  acceptCookies() {
    // Set types cookies to true
    this.setCookie('cookizi', true)

    for (let i = 0; i < this.types.length; i++) {
      this.setCookie(this.types[i], true)
    }

    // Launch
    this.launch()

    // Hide cookie bar
    this.bar.style.display = 'none'
  }

  refuseCookies() {
    // Set types cookies to false
    for (let i = 0; i < this.types.length; i++) {
      this.setCookie(this.types[i], false)
    }

    // Hide cookie bar
    this.bar.style.display = 'none'
  }

  registerSidebarCookies() {
    for (let i = 0; i < this.types.length; i++) {
      const value = this.sidebar.querySelector(`.cookizi-sidebar__switch[data-type="${this.types[i]}"]`).dataset.value
      this.setCookie(this.types[i], value == 1 ? true : false)
    }
  }

  launch() {
    for (let i = 0; i < this.types.length; i++) {
      if(this.getCookie(this.types[i]) && this.launched.indexOf(this.types[i]) == -1) {
        // If type is true and not ever launched, execute each script tags
        const scripts = document.querySelectorAll(`script[data-cookizi="${this.types[i]}"]`)
        for (let i = 0; i < scripts.length; i++) {
          eval(scripts[i].innerText)
          const url = scripts[i].getAttribute('src');

          if(url) {
            var head = document.getElementsByTagName("head")[0], done = false;
            var script = document.createElement("script");
            script.src = url;
            head.appendChild(script);
          }
        }
        // Add type to ever launched array
        this.launched.push(this.types[i])
      } else if(!this.getCookie(this.types[i]) && this.deleted.indexOf(this.types[i]) == -1) {
        // If type is false and not ever deleted, remove cookies
        for (let j = 0; j < this.cookies[this.types[i]].length; j++) {
          this.deleteCookie(this.cookies[this.types[i]][j])
        }
        // Add type to ever deleted array
        this.deleted.push(this.types[i])
      } 
    }
  }

  answered() {
    if(this.getCookie('cookizi')) {
      return true
    }
    return false
  }

  setCookie(name, value) {
    const date = new Date()
    date.setTime(date.getTime() + this.cookieTimeout)
    document.cookie = name + '=' + value + ';expires=' + date.toGMTString() + ';path=/'
  }

  getCookie(name) {
    if (document.cookie.indexOf(name + '=true') > -1) {
      return true
    } else if (document.cookie.indexOf(this.cookieName + '=false') > -1) {
      return false
    }
    return false
  }

  deleteCookie(name) {
    let hostname = document.location.hostname
    if (hostname.indexOf('www.') === 0) {
      hostname = hostname.substring(4)
    }
    document.cookie = name + '=; domain=.' + hostname + '; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/'
    document.cookie = name + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/'
  }

  isBot () {
    return this.bots.test(navigator.userAgent)
  }

  isToTrack() {
    const dnt = navigator.doNotTrack || navigator.msDoNotTrack || window.doNotTrack
    return (dnt !== null && dnt !== undefined) ? (dnt && dnt !== 'yes' && dnt !== 1 && dnt !== '1') : true
  }
}