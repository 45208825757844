import "@scss/main.scss";
import "picturefill";
import "lazysizes";
import { makeBrowserDetector } from "./libs/BrowserDetector";
import { Utm } from "./libs/Utm";

import Cookizi from './libs/Cookizi'
import Accordino from './libs/Accordino'
import { Swiper, EffectFade } from 'swiper'
Swiper.use([EffectFade])
import SmoothScroll from 'smooth-scroll'

// register CSS classes on <html> to help with browser detection in CSS
/* const B = */ makeBrowserDetector(window).bootstrapClasses()

document.addEventListener("DOMContentLoaded", function(){
	// Burger
	const burger = document.querySelector('.a-burger')
	if(burger && window.innerWidth < 1024) {
		const clickOutside = e => {
			let targetElement = e.target
			do {
				if (targetElement == burger)
					return
				targetElement = targetElement.parentNode
			} while (targetElement)
			burger.classList.remove('a-burger--shown')
			document.removeEventListener('click', clickOutside, false)
		}
		burger.querySelector('.a-burger__container').addEventListener('click', () => {
			burger.classList.toggle('a-burger--shown')
			if(burger.classList.contains('a-burger--shown')) {
				document.addEventListener('click', clickOutside, false)
			} else {
				document.removeEventListener('click', clickOutside, false)
			}
		})
	}
	// Header on scroll
	const longScroll = window.innerHeight / 2
    window.addEventListener('scroll', e => {
        if(window.scrollY > 40 && !document.body.classList.contains('o-body--scrolled')) {
            document.body.classList.add('o-body--scrolled')
        } else if(window.scrollY <= 40) {
            document.body.classList.remove('o-body--scrolled')
        }
        if(window.scrollY > longScroll && !document.body.classList.contains('o-body--long-scrolled')) {
            document.body.classList.add('o-body--long-scrolled')
        } else if(window.scrollY <= longScroll) {
            document.body.classList.remove('o-body--long-scrolled')
        }
	})

	// Smooth scroll
	new SmoothScroll('a[href*="#"]')

	// Advantages
	const advantages = document.querySelectorAll('.m-advantages__cards')
	if(advantages) {
		advantages.forEach(advantage => {
			new Swiper(advantage, {
				slidesPerView: 'auto',
				spaceBetween: 20
			})	
		})
	}

	// Offers
	const offers = document.querySelectorAll('.m-offers__card')
	if(offers && window.innerWidth < 1024) {
		offers.forEach(offer => {
			new Accordino(offer)
		})
	} else if(offers && window.innerWidth >= 1024) {
		const offers = new Swiper('.m-offers__cards', {
			effect: 'fade',
			fadeEffect: {
			  crossFade: true
			},
			autoHeight: true,
			simulateTouch: false
		})
		// Tabs click
		const tabs = document.querySelectorAll('.m-offers__tab')
		tabs.forEach(tab => {
			tab.addEventListener('click', e => {
				console.log(tab.dataset.slide)
				e.preventDefault()
				offers.slideTo(tab.dataset.slide)
			})	
		})
		// Card change
		offers.on('slideChange', () => {
			document.querySelector('.m-offers__tab--active').classList.remove('m-offers__tab--active')
			document.querySelector(`.m-offers__tab[data-slide="${offers.realIndex}"]`).classList.add('m-offers__tab--active')
		})
	}

	// Cookies
	const cookizi = new Cookizi({
		types: ['analytics']
	})
	if(document.querySelector('.o-footer__link')) {
		document.querySelector('.o-footer__link:last-child').addEventListener('click', e => {
			e.preventDefault()
			cookizi.showSidebar()
		})
	}

	// UTM to Cookies
	const utm = new Utm()
	utm.makeCookies()
})

// SWPL LOG
console.log(
	"%c S W P L ",
	`background: #1467ff;
	color: white;
	font-size: 17px;
	font-weight: bold;
	line-height: 36px; text-align: center`,
	" www.swpl.fr"
);
